@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");
body {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #646464;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
  color: #ff4157;
}

i,
span,
a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400 !important;
  color: #121619;
  margin: 0px;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  color: #646464;
  margin: 0px;
}
@media (max-width: 767px) {
  p {
    font-size: 16px;
  }
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.second-nav {
  width: 100%;
  padding: 1.8em 2em;
  display: flex;
}

.second-nav img {
  width: 100px;
}

.mb-menu {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: #fff;
  padding: 3em 2em;
  z-index: 999;
  position: fixed;
}

.mb-menu .icon {
  margin-left: auto;
  color: #9a9a9a;
  font-weight: 400;
}

.mb-menu .menu-flx {
  display: flex;
  flex-direction: column;
}

.mb-menu .menu-flx .list {
  font-size: 25px;
  padding: 1em 0em;
  color: #303030;
  font-weight: 400;
  border-bottom: 1px solid #dcd9d9;
}

/*===== All Button Style =====*/
.main-btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 30px;
  font-size: 16px;
  line-height: 48px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  background-color: #ff4157;
  border-radius: 3px;
  border: 1px solid #ff4157;
}
.main-btn i {
  margin-right: 5px;
}
.main-btn:hover {
  background-color: #fff;
  border-color: #ff4157;
  color: #ff4157;
}
.main-btn.main-btn-2 {
  background-color: #fff;
  color: #ff4157;
  border-color: #ff4157;
}
.main-btn.main-btn-2:hover {
  background-color: #ff4157;
  border-color: #ff4157;
  color: #fff;
}

/*===== All Section Title Style =====*/
.section-title .sub-title {
  font-size: 18px;
  color: #ff4157;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .section-title .sub-title {
    font-size: 16px;
  }
}

.section-title .title {
  font-size: 35px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .section-title .title {
    font-size: 24px;
  }
}

@media (max-width: 960px) {
  .resp-ico {
    display: block !important;
  }
}

/*===== All Animation Title Style =====*/
.animation-one {
  -webkit-animation: rotate 15s linear infinite;
  -moz-animation: rotate 15s linear infinite;
  -o-animation: rotate 15s linear infinite;
  animation: rotate 15s linear infinite;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*===== All Preloader Style =====*/
.preloader {
  /* Body Overlay */
  position: fixed;
  top: 0;
  left: 0;
  display: table;
  height: 100%;
  width: 100%;
  /* Change Background Color */
  background: #fff;
  z-index: 99999;
}
.preloader .loader_34 {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.preloader .loader_34 .ytp-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 64px;
  margin-left: -32px;
  z-index: 18;
  pointer-events: none;
}
.preloader .loader_34 .ytp-spinner .ytp-spinner-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
  padding-bottom: 100%;
  top: 50%;
  left: 50%;
  margin-top: -50%;
  margin-left: -50%;
  -webkit-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
  -moz-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
  -o-animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
  animation: ytp-spinner-linspin 1568.23529647ms linear infinite;
}
.preloader .loader_34 .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  -moz-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  -o-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
}
.preloader
  .loader_34
  .ytp-spinner
  .ytp-spinner-container
  .ytp-spinner-rotator
  .ytp-spinner-left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  right: 50%;
}
.preloader
  .loader_34
  .ytp-spinner
  .ytp-spinner-container
  .ytp-spinner-rotator
  .ytp-spinner-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  left: 50%;
}
.preloader .loader_34 .ytp-spinner-circle {
  box-sizing: border-box;
  position: absolute;
  width: 200%;
  height: 100%;
  border-style: solid;
  /* Spinner Color */
  border-color: #ff4157 #ff4157 #ededed;
  border-radius: 50%;
  border-width: 6px;
}
.preloader .loader_34 .ytp-spinner-left .ytp-spinner-circle {
  left: 0;
  right: -100%;
  border-right-color: #ededed;
  -webkit-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  -moz-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  -o-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
    infinite both;
  animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
}
.preloader .loader_34 .ytp-spinner-right .ytp-spinner-circle {
  left: -100%;
  right: 0;
  border-left-color: #ededed;
  -webkit-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
  -moz-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
    both;
  -o-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

/* Preloader Animations */
@-webkit-keyframes ytp-spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ytp-spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes ytp-spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
    -moz-transform: rotate(405deg);
    -ms-transform: rotate(405deg);
    -o-transform: rotate(405deg);
    transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
    -moz-transform: rotate(540deg);
    -ms-transform: rotate(540deg);
    -o-transform: rotate(540deg);
    transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
    -moz-transform: rotate(675deg);
    -ms-transform: rotate(675deg);
    -o-transform: rotate(675deg);
    transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
    -moz-transform: rotate(810deg);
    -ms-transform: rotate(810deg);
    -o-transform: rotate(810deg);
    transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
    -moz-transform: rotate(945deg);
    -ms-transform: rotate(945deg);
    -o-transform: rotate(945deg);
    transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
    -moz-transform: rotate(1080deg);
    -ms-transform: rotate(1080deg);
    -o-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}

@keyframes ytp-spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
    -moz-transform: rotate(405deg);
    -ms-transform: rotate(405deg);
    -o-transform: rotate(405deg);
    transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
    -moz-transform: rotate(540deg);
    -ms-transform: rotate(540deg);
    -o-transform: rotate(540deg);
    transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
    -moz-transform: rotate(675deg);
    -ms-transform: rotate(675deg);
    -o-transform: rotate(675deg);
    transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
    -moz-transform: rotate(810deg);
    -ms-transform: rotate(810deg);
    -o-transform: rotate(810deg);
    transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
    -moz-transform: rotate(945deg);
    -ms-transform: rotate(945deg);
    -o-transform: rotate(945deg);
    transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
    -moz-transform: rotate(1080deg);
    -ms-transform: rotate(1080deg);
    -o-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}

@-webkit-keyframes ytp-spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg);
  }
}

@keyframes ytp-spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
    -moz-transform: rotate(130deg);
    -ms-transform: rotate(130deg);
    -o-transform: rotate(130deg);
    transform: rotate(130deg);
  }
}

@-webkit-keyframes ytp-right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
}

@keyframes ytp-right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
    -moz-transform: rotate(-130deg);
    -ms-transform: rotate(-130deg);
    -o-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
}

/*===========================
    2.HEADER css 
===========================*/
.header-area {
  position: relative;
}
.header-area .shape {
  position: absolute;
}
.header-area .header-shape-one {
  top: 0;
  left: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-shape-one {
    width: 230px;
  }
}
@media (max-width: 767px) {
  .header-area .header-shape-one {
    width: 180px;
  }
}
.header-area .header-shape-tow {
  top: 25px;
  left: 31%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-shape-tow {
    left: 40%;
  }
}
@media (max-width: 767px) {
  .header-area .header-shape-tow {
    left: 60%;
    width: 60px;
  }
}
.header-area .header-shape-three {
  left: 70px;
  bottom: 130px;
}
@media (max-width: 767px) {
  .header-area .header-shape-three {
    width: 90px;
    bottom: 200px;
  }
}
.header-area .header-shape-fore {
  left: 48%;
  bottom: -90px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-area .header-shape-fore {
    width: 260px;
  }
}
@media (max-width: 767px) {
  .header-area .header-shape-fore {
    bottom: -60px;
    width: 210px;
  }
}

.navigation-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  padding: 25px 0;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.navigation-bar.sticky {
  position: fixed;
  background-color: #fff;
  z-index: 99;
  padding: 15px 0;
  box-shadow: 0 0 20px 0 rgba(10, 16, 21, 0.08);
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #1b1b1b75;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navigation-bar.sticky .navbar-btn {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  .navigation-bar.sticky .navbar-btn {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.navbar {
  padding: 0;
  position: relative;
}
.navbar .navbar-brand img {
  max-width: 90px;
}
.navbar .navbar-toggler .toggler-icon {
  width: 30px;
  height: 2px;
  display: block;
  background-color: #646464;
  margin: 5px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-collapse {
    position: absolute;
    top: 128%;
    width: 100%;
    left: 0;
    background-color: #fff;
    padding: 5px 15px;
    z-index: 9;
    -webkit-box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
    -moz-box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
    box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
  }
}
@media (max-width: 767px) {
  .navbar .navbar-collapse {
    position: absolute;
    top: 128%;
    width: 100%;
    left: 0;
    background-color: #fff;
    padding: 5px 15px;
    z-index: 9;
    -webkit-box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
    -moz-box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
    box-shadow: 0px 5px 25px 0px rgba(244, 128, 74, 0.3);
  }
}
.navbar .navbar-nav .nav-item {
  margin-right: 45px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .navbar .navbar-nav .nav-item {
    margin-right: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-nav .nav-item {
    margin: 5px 0;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-nav .nav-item {
    margin: 5px 0;
  }
}
.navbar .navbar-nav .nav-item a {
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .navbar .navbar-nav .nav-item a {
    font-size: 16px;
  }
}
.navbar .navbar-nav .nav-item.active a,
.navbar .navbar-nav .nav-item:hover a {
  color: #b5b5b5;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .navbar-btn {
    position: absolute;
    right: 70px;
    top: 0;
  }
}
@media (max-width: 767px) {
  .navbar .navbar-btn {
    position: absolute;
    right: 70px;
    top: 0;
  }
}

.header-banner {
  height: 700px;
  position: relative;
  background-image: url(https://images.pexels.com/photos/1648776/pexels-photo-1648776.jpeg?auto=compress);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center bottom;
}

.footer-subtext {
  text-transform: uppercase;
  font-weight: 400 !important;
  letter-spacing: 4px;
  color: #444;
}

.contact-box {
  padding: 4em 0em;
}

.contact-list {
  padding: 2em 0em;
}

.contact-list .item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e2e8eb;
  font-size: 1rem;
  font-weight: 500;
  padding: 12px 0;
  color: #131313;
}

.contact-list .item .icon {
  margin-right: 14px;
  color: #ff4157;
}

.footer-flex {
  background-color: #0a1015;
  color: #fff;
  padding: 3em 0em;
}

.footer-flex .logo {
  margin-bottom: 2em;
}

.footer-flex .headline {
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-banner {
    height: 500px;
  }
}
@media (max-width: 767px) {
  .header-banner {
    height: 500px;
  }
}
.header-banner .banner-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 55%;
  height: 100%;
}
.header-banner .banner-content {
  position: relative;
  z-index: 9;
}
.header-banner .banner-content .sub-title {
  font-size: 24px;
  color: #fff;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .header-banner .banner-content .sub-title {
    font-size: 18px;
  }
}
.header-banner .banner-content .banner-title {
  font-size: 60px;
  color: #fff;
}
.header-banner .banner-content .banner-title span {
  color: #fff;
}
@media (max-width: 767px) {
  .header-banner .banner-content .banner-title {
    font-size: 38px;
  }
}
.header-banner .banner-content .banner-contact {
  position: relative;
  font-size: 15px;
  font-weight: 400;
  font-family: "Rubik", sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin-left: 70px;
}
.header-banner .banner-content .banner-contact::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  background-color: #fff;
  left: -70px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*===========================
    3.ABOUT css 
===========================*/
@media (max-width: 767px) {
  .about-area {
    padding-top: 30px;
    padding-bottom: 80px;
  }
}

.about-image {
  position: relative;
}
.about-image::before {
  position: absolute;
  content: "";
  background-image: url(images/about/dots.jpg);
  background-position: center center;
  background-size: 100%;
  width: 375px;
  height: 350px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}
@media (max-width: 767px) {
  .about-image::before {
    display: none;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image::before {
    display: block;
  }
}
.about-image .single-image {
  width: 360px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-image .single-image {
    width: 280px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-image .single-image {
    width: 400px;
  }
}
@media (max-width: 767px) {
  .about-image .single-image {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image .single-image {
    width: 360px;
  }
}
.about-image .single-image img {
  width: 100%;
}
.about-image .single-image.image-tow {
  margin-top: -200px;
  position: relative;
  z-index: 5;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .about-image .single-image.image-tow {
    margin-top: -150px;
  }
}
@media (max-width: 767px) {
  .about-image .single-image.image-tow {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image .single-image.image-tow {
    margin-top: -200px;
  }
}
.about-image .about-btn {
  position: relative;
  margin-left: 80px;
  margin-top: -40px;
  display: inline-block;
  z-index: 9;
}
@media (max-width: 767px) {
  .about-image .about-btn {
    margin: 0 auto;
    display: block;
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image .about-btn {
    margin-left: 80px;
    display: inline-block;
    text-align: left;
    margin-top: -50px;
  }
}
.about-image .about-btn .main-btn {
  line-height: 58px;
  padding: 0 40px;
  border: 8px solid #fff;
  font-weight: 500;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.about-image .about-btn .main-btn span {
  font-size: 35px;
}
@media (max-width: 767px) {
  .about-image .about-btn .main-btn span {
    font-size: 28px;
  }
}
.about-image .about-btn .main-btn:hover {
  border-color: #ff4157;
}
@media (max-width: 767px) {
  .about-image .about-btn .main-btn {
    line-height: 48px;
    padding: 0 30px;
    font-size: 14px;
    margin-top: -25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-image .about-btn .main-btn {
    line-height: 48px;
    padding: 0 30px;
    font-size: 14px;
    margin-top: -25px;
  }
}

.about-content .about-welcome {
  font-size: 16px;
  color: #4b4b4b;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 21px;
  font-weight: 400 !important;
}
@media (max-width: 767px) {
  .about-content .about-welcome {
    font-size: 18px;
  }
}

.about-content .about-title {
  font-size: 35px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .about-content .about-title {
    font-size: 26px;
  }
}

/*===========================
      4.SERVICES css 
===========================*/
@media (max-width: 767px) {
  .services-area {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}

.single-services {
  padding: 50px 30px;
  background-color: #fff;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .single-services {
    padding: 30px 10px;
  }
}
@media (max-width: 767px) {
  .single-services {
    padding: 30px 10px;
  }
}
.single-services .services-icon i {
  font-size: 52px;
  color: #ff4157;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.single-services .services-content .services-title {
  font-size: 22px;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.single-services:hover {
  background-color: #ff4157;
}
.single-services:hover .services-icon i {
  color: #fff;
}
.single-services:hover .services-content .services-title,
.single-services:hover .services-content p {
  color: #fff;
}

/*===========================
      5.PROJECT css 
===========================*/
@media (max-width: 767px) {
  .project-area {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}

.single-project {
  position: relative;
}
.single-project .project-image {
  position: relative;
}
.single-project .project-image::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: white;
  background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 50%, #ff4157 100%);
  background: -o-linear-gradient(rgba(255, 255, 255, 0) 50%, #ff4157 100%);
  background: linear-gradient(rgba(255, 255, 255, 0) 50%, #ff4157 100%);
  opacity: 0;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.single-project .project-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 35px 5px;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  opacity: 0;
}
.single-project .project-content .project-title {
  font-size: 22px;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  position: relative;
  color: #fff;
  margin-left: 60px;
}
.single-project .project-content .project-title::before {
  position: absolute;
  content: "";
  width: 50px;
  height: 1px;
  background-color: #fff;
  top: 50%;
  left: -60px;
}
.single-project:hover .project-image::before {
  opacity: 1;
}
.single-project:hover .project-content {
  opacity: 1;
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
  transform: translateY(0%);
}

.project-active .slick-dots {
  text-align: center;
  margin-top: 50px;
}
.project-active .slick-dots li {
  display: inline-block;
  margin: 0 7px;
}
.project-active .slick-dots li button {
  width: 25px;
  height: 15px;
  border-radius: 50px;
  border: 0;
  background-color: rgba(235, 54, 86, 0.7);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  font-size: 0;
  cursor: pointer;
}
.project-active .slick-dots li.slick-active button {
  width: 35px;
  background-color: #ff4157;
}

/*===========================
        6.TEAM css 
===========================*/
@media (max-width: 767px) {
  .team-area {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}

.single-team .team-image img {
  width: 100%;
}

.single-team .team-content {
  width: 80%;
  margin: 0 auto;
  padding: 30px 15px;
  position: relative;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .single-team .team-content {
    padding: 30px 0;
  }
}
.single-team .team-content .team-name a {
  font-size: 18px;
  font-weight: 600;
  color: #121619;
  font-family: "Rubik", sans-serif;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.single-team .team-content .team-name a:hover {
  color: #ff4157;
}
.single-team .team-content .sub-title {
  font-size: 14px;
  color: #646464;
}
.single-team .team-content .social {
  margin-top: -35px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.single-team .team-content .social li {
  display: inline-block;
  margin: 0 7px;
}
.single-team .team-content .social li a {
  font-size: 16px;
  color: #646464;
  width: 30px;
  height: 30px;
  line-height: 35px;
  text-align: center;
  border-radius: 50%;
  background-color: #ededed;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.single-team .team-content .social li a:hover {
  background-color: #ff4157;
  color: #fff;
}

.single-team:hover .team-content {
  background-color: #fff;
  margin-top: -60px;
  -webkit-box-shadow: 0px 6px 16px 0px rgba(235, 54, 86, 0.34);
  -moz-box-shadow: 0px 6px 16px 0px rgba(235, 54, 86, 0.34);
  box-shadow: 0px 6px 16px 0px rgba(235, 54, 86, 0.34);
}
.single-team:hover .team-content .social {
  margin-top: 25px;
  opacity: 1;
  visibility: visible;
}

/*===========================
      7.TESTIMONIAL css 
===========================*/
.testimonial-area {
  position: relative;
}
@media (max-width: 767px) {
  .testimonial-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.testimonial-area .shape {
  position: absolute;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .testimonial-area .shape img {
    width: 325px;
  }
}
.testimonial-area .shape-one {
  top: 25px;
  left: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .testimonial-area .shape-one {
    left: 20px;
  }
}
.testimonial-area .shape-tow {
  left: 150px;
  bottom: 50px;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .testimonial-area .shape-tow {
    left: 70px;
  }
}
.testimonial-area .shape-three {
  right: 85px;
  bottom: 30px;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .testimonial-area .shape-three {
    right: 35px;
  }
}

.testimonial-bg {
  position: relative;
  z-index: 9;
}

.testimonial-active {
  background-color: #ff4157;
  padding: 60px 30px;
}
@media (max-width: 767px) {
  .testimonial-active {
    padding: 30px 15px;
  }
}

.single-testimonial .testimonial-image {
  position: relative;
  display: inline-block;
}
.single-testimonial .testimonial-image img {
  border-radius: 50%;
}
.single-testimonial .testimonial-image .quota {
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
}
.single-testimonial .testimonial-image .quota i {
  font-size: 18px;
  color: #ff4157;
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  background-color: #fff;
  border: 2px solid #ff4157;
  border-radius: 50%;
}

.single-testimonial .testimonial-content p {
  font-size: 18px;
  color: #fff;
  font-family: "Rubik", sans-serif;
}

.single-testimonial .testimonial-content .testimonial-name {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}

.single-testimonial .testimonial-content .sub-title {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  color: #fff;
}

.testimonial-active .slick-dots {
  text-align: center;
  margin-top: 10px;
}
.testimonial-active .slick-dots li {
  display: inline-block;
  margin: 0 5px;
}
.testimonial-active .slick-dots li button {
  width: 18px;
  height: 15px;
  border-radius: 50px;
  border: 0;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  font-size: 0;
  cursor: pointer;
}
.testimonial-active .slick-dots li.slick-active button {
  background-color: #fff;
  width: 25px;
}

/*===========================
       8.CONTACT css 
===========================*/
@media (max-width: 767px) {
  .contact-area {
    padding-top: 75px;
    padding-bottom: 80px;
  }
}

.form-group {
  margin: 0;
}

p.form-message.success,
p.form-message.error {
  font-size: 16px;
  color: #333;
  background: #ddd;
  padding: 10px 15px;
  margin-left: 15px;
  margin-top: 15px;
}

p.form-message.error {
  color: #f00;
}

.contact-form .single-form {
  margin-top: 30px;
}
.contact-form .single-form textarea,
.contact-form .single-form input {
  width: 100%;
  height: 60px;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 0 30px;
  background-color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
}
.contact-form .single-form textarea {
  height: 160px;
  padding-top: 20px;
  resize: none;
}
.contact-form .single-form textarea::placeholder,
.contact-form .single-form input::placeholder {
  opacity: 1;
  color: #999;
}

.list-unstyled li {
  font-size: 13px;
  margin-left: 2px;
  margin-top: 5px;
  color: #f00;
}

/*===========================
         9.MAP css 
===========================*/
.map-area {
  position: relative;
  height: 600px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .map-area {
    height: 450px;
  }
}
@media (max-width: 767px) {
  .map-area {
    height: 450px;
  }
}

.mapouter {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mapouter {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .mapouter {
    width: 100%;
  }
}
.mapouter .gmap_canvas iframe {
  width: 100%;
  height: 600px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mapouter .gmap_canvas iframe {
    height: 450px;
  }
}
@media (max-width: 767px) {
  .mapouter .gmap_canvas iframe {
    height: 450px;
  }
}

.map-bg {
  width: 40%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

/*===========================
       10.FOOTER css 
===========================*/
@media (max-width: 767px) {
  .footer-widget {
    padding-top: 30px;
    padding-bottom: 80px;
  }
}

.footer-logo a img {
  width: 140px;
}

.footer-logo p {
  line-height: 26px;
  font-size: 16px;
}

.footer-logo .footer-info li {
  margin-top: 20px;
}
.footer-logo .footer-info li .single-info {
  position: relative;
}
.footer-logo .footer-info li .single-info .info-icon {
  position: absolute;
  top: 3px;
  left: 0;
}
.footer-logo .footer-info li .single-info .info-icon i {
  font-size: 18px;
  color: .footer-logo .footer-info li .single-info .info-icon i theme-color;
}
.footer-logo .footer-info li .single-info .info-content {
  padding-left: 30px;
}

.footer-logo .footer-social li {
  display: inline-block;
  margin-left: 15px;
}
.footer-logo .footer-social li:first-child {
  margin-left: 0;
}
.footer-logo .footer-social li a {
  width: 35px;
  height: 35px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff5f1;
  color: #646464;
  font-size: 16px;
  -webkit-box-shadow: 0px 5px 21px 0px rgba(254, 119, 101, 0.4);
  -moz-box-shadow: 0px 5px 21px 0px rgba(254, 119, 101, 0.4);
  box-shadow: 0px 5px 21px 0px rgba(254, 119, 101, 0.4);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.footer-logo .footer-social li a:hover {
  background-color: #ff4157;
  color: #fff;
}

.f-title .title {
  font-size: 22px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}

.footer-link ul li {
  padding-top: 5px;
}
.footer-link ul li a {
  font-size: 16px;
  color: #646464;
  font-family: "Rubik", sans-serif;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}
.footer-link ul li a:hover {
  color: #ff4157;
}

.footer-newsleter p {
  line-height: 26px;
  font-size: 16px;
}

.footer-newsleter .newsleter {
  position: relative;
}
.footer-newsleter .newsleter input {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  border: 1px solid #ff4157;
  background-color: #ffeae1;
}
.footer-newsleter .newsleter button {
  border: 0;
  width: 66px;
  text-align: center;
  padding: 0;
  height: 50px;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  color: #ff4157;
  font-size: 20px;
  line-height: 57px;
  cursor: pointer;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .footer-newsleter .newsleter button {
    width: 45px;
  }
}
.footer-newsleter .newsleter button::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 1px;
  height: 36px;
  background-color: #ff4157;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.copyright-area {
  border-top: 1px solid #eceff8;
  padding: 13px 0;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 18px;
  width: 40px;
  height: 40px;
  line-height: 46px;
  z-index: 99;
  text-align: center;
  display: none;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(156, 156, 156, 0.25);
  -moz-box-shadow: 0px 0px 30px 0px rgba(156, 156, 156, 0.25);
  box-shadow: 0px 0px 30px 0px rgba(156, 156, 156, 0.25);
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  color: #fff;
  background-color: #ff4157;
}
.back-to-top:hover {
  color: #ff4157;
  background-color: #fff;
}
