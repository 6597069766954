.product-col {
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
}

.product-col img {
  border-radius: 5px;
  width: 350px;
  height: 350px;
  align-self: center;
}

.product-col .h5 {
  font-weight: 400 !important;
}

.product-col .product-info {
  margin-top: 40px;
  padding: 0px 10px;
  display: flex;
}

.product-info svg {
  margin-left: auto;
  opacity: 0;
  transition: all 0.3s ease-in;
}

.product-col:hover .product-info svg {
  opacity: 1;
}

.resp-ico {
  display: none;
}
